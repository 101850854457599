<template>
  <v-layout wrap justify-center id="cf">
    <ServerError v-if="ServerError" />
    <vue-element-loading
      :active="appLoading"
      :is-full-screen="true"
      background-color="#FFFFFF"
      color="blue"
      spinner="mini-spinner"
    />
    <v-flex xs12 lg11 pa-sm-4 pa-lg-6>
      <v-layout wrap justify-center>
        <v-flex xs12>
          <v-card
            class="mx-auto elevation-1 pa-6"
            align="center"
            outlined
          >
            <!---------------------add asset-------------------------->
            <v-layout wrap>
              <v-flex xs12>
                <v-form ref="form1" v-model="valid1">
                  <v-layout wrap>
                    <v-flex
                      xs12
                      sm4
                      md4
                      lg4
                      pb-2
                      pb-md-0
                      class="text-left nsbold"
                      style="font-weight: 700; font-size: 20px"
                      >Floor List
                    </v-flex>
                     <v-flex
                      xs12
                      sm3
                      md3
                      lg3
                      pb-2 pr-2
                      pb-md-0
                      align-self-center
                      class="text-left nsregular"
                    >
                      <v-select
                    :items="HostelList"
                    v-model="hostel"
                    label="Hostel"
                    item-text="hostelName"
                    item-value="_id"
                    clearable
                    class="rounded-xl"
                    outlined
                    hide-details
                    dense
                  ></v-select>
                    </v-flex>
                    <v-flex
                      xs12
                      sm3
                      md3 
                      lg3
                      pb-2
                      pb-md-0
                      align-self-center
                      class="text-left nsregular"
                    >
                      <v-text-field
                        v-model="keyword"
                        dense
                        class="rounded-xl"
                        label="Search"
                        outlined
                        hide-details
                        clearable
                      ></v-text-field>
                    </v-flex>
                    <v-flex
                      xs12
                      sm2
                      md2
                      pb-0
                      pb-sm-2
                      pb-md-0
                      px-1
                      align-self-center
                    >
                      <!-- <v-card rounded outlined  style="cursor: pointer" color="transparent" @click="adddialogue = true"> -->
                        <v-btn
                       block
                        dark
                        color="#766BC0"
                        class="rounded-lg hidden-sm-and-up"
                        @click="adddialogue = true"
                      >
                        <v-icon x-small>mdi-plus</v-icon>
                        <span
                          class="text-left nsregular"
                          style="font-weight: 400; font-size: 12px"
                          >Add Asset</span
                        ></v-btn
                      >
                      <v-btn
                        width="120px"
                        dark
                         style="text-transform: none; border-radius: 40px;"
                        color="#766BC0"
                        @click="adddialogue = true"
                        class="hidden-xs-only"
                      >
                        <!-- class="rounded-lg hidden-xs-only" -->

                        <v-icon x-small>mdi-plus</v-icon>
                        <span
                          class="text-left nsregular"
                          style="font-weight: 400; font-size: 13px"
                          >Add Floor</span
                        ></v-btn
                      >
                      <!-- </v-card> -->
                    </v-flex>
                  </v-layout>
                  <!-- <v-layout wrap pt-2 pb-4>
                <v-flex xs12 lg2 pr-lg-1>
                  <v-text-field
                    v-model="name"
                    dense
                    label="Name"
                    outlined
                    :rules="[rules.required]"
                    hide-details
                  ></v-text-field>
                </v-flex>
                <v-flex xs12 lg2 pt-8 pt-lg-0 pr-lg-1>
                  <v-text-field
                    v-model="assetType"
                    dense
                    :rules="[rules.required]"
                    label="Asset Type"
                    outlined
                    hide-details
                  ></v-text-field>
                </v-flex>
                <v-flex xs12 lg2 pt-8 pt-lg-0 pr-lg-1>
                  <v-text-field
                    v-model="description"
                    dense
                    label="Description"
                    outlined
                    hide-details
                  ></v-text-field>
                </v-flex>
                
                <v-flex xs12 lg3 pr-lg-12 pt-2 pt-lg-0>
                  <v-btn
                    block
                    dark
                    color="primary"
                    dense
                    @click="addCategory()"
                  >
                    Add <v-icon x-small>mdi-plus</v-icon>
                  </v-btn>
                </v-flex>
                <v-flex
                  xs12
                  lg3
                  pl-lg-12
                  align-self-center
                  class="text-left nsbold"
                  style="font-weight: 700; font-size: 20px"
                >
                  <v-text-field
                    v-model="keyword"
                    dense
                    class="rounded-xl"
                    label="Search"
                    outlined
                    hide-details
                    clearable
                  ></v-text-field>
                </v-flex>
              </v-layout> -->
                </v-form>
              </v-flex>
            </v-layout>
            <!---------------------------------------------------->
            <!-- <v-layout wrap>
              <v-flex
                xs12
                lg6
                class="text-left nsbold"
                style="font-weight: 700; font-size: 20px"
                >
              </v-flex>
            </v-layout> -->
            <!-- <v-layout py-3>
              <v-flex
                xs2
                class="text-left nsbold"
                style="font-weight: 700; font-size: 18px"
              >
                <span>No.</span>
              </v-flex>
              <v-flex
                xs3
                class="text-left nsbold"
                style="font-weight: 600; font-size: 18px"
              >
                <span>Name</span>
              </v-flex>
              <v-flex
                xs3
                class="text-left nsbold"
                style="font-weight: 600; font-size: 18px"
              >
                <span>Type</span>
              </v-flex>
              <v-flex
                xs3
                class="text-left nsbold"
                style="font-weight: 600; font-size: 18px"
              >
                <span>Description</span>
              </v-flex>
            </v-layout>
            <v-layout
              v-for="(item, i) in student"
              :key="i"
              py-2
              :style="i % 2 == 0 ? 'background-color:#FAFAFA' : ''"
            >
              <v-flex
                xs2
                class="text-left regular"
                style="font-weight: 400; font-size: 18px"
              >
                <span>{{ i + 1 }}</span>
              </v-flex>
              <v-flex
                xs3
                class="text-left nsregular"
                style="font-weight: 400; font-size: 18px"
              >
                <span>{{ item.name }}</span>
              </v-flex>
              <v-flex xs3 class="text-left nsregular"
                style="font-weight: 400; font-size: 18px">
                <span>{{ item.assetType }}</span>
                </v-flex>
                <v-flex xs3 class="text-left nsregular"
                style="font-weight: 400; font-size: 18px">
                <span>{{ item.description }}</span>
              </v-flex>
              <v-flex xs1 v-if="item">
                <span
                  ><v-icon
                    style="cursor: pointer"
                    @click="(curItem = item), (editdialogue = true)"
                    >mdi-circle-edit-outline</v-icon
                  ></span
                >
              </v-flex>
              <v-flex xs1>
                <span
                  ><v-icon
                    style="cursor: pointer"
                    @click="(dialoge = true), (curId = item._id)"
                    >mdi-delete-outline</v-icon
                  ></span
                >
              </v-flex>
              <v-divider></v-divider>
            </v-layout> -->

            <v-flex xs12 v-if="student">
              <v-simple-table>
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th class="text-left">No.</th>
                      <th class="text-left">Name</th>
                      <th class="text-left">Floor.No</th>
                      <th class="text-left">Hostel</th>
                      <th class="text-left">Edit</th>
                      <th class="text-left">Delete</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(value, i) in student" :key="i">
                      <!-- <td>{{ i + 1 }}</td> -->
                     <td> <span v-if="page == 1">
                          {{ i + 1 }}
                        </span>
                        <span v-else>
                          {{ i+1+(20*(page-1)) }}  
                        </span></td>
                      <td>{{ value.floorName }}</td>
                      <td>{{ value.floorNo }}</td>
                      <td>{{ value.hostelName.hostelName }}</td>
                      <td>
                        <v-icon small
                          style="cursor: pointer"
                          @click="(curItem = value), (editdialogue = true)"
                          >mdi-circle-edit-outline</v-icon
                        >
                      </td>
                      <td>
                        <v-icon small
                          style="cursor: pointer"
                          @click="(dialoge = true),  (curId = value._id)"
                          >mdi-delete-outline</v-icon
                        >
                      </td>

                      <!-- <td>{{ value.studentid[0].admissionNo }}</td>
                                <td>{{ value.clothid.clothType.name }}</td>
                                <td>{{formatDate(value.INdate)}}</td> -->
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-flex>
          </v-card>
        </v-flex>
      </v-layout>
<!-- delete -->
      <v-dialog width="400px" v-model="dialoge">
        <v-card width="400px">
          <v-toolbar dark color="#766BC0" dense flat class="body-2">
            <v-toolbar-title> Confirm </v-toolbar-title>
          </v-toolbar>
          <v-card-text class="pa-4"
            >Are you sure you want to delete this record?
          </v-card-text>
          <v-card-actions class="pt-3">
            <v-spacer></v-spacer>
            <v-btn
              color="grey"
              text
              class="body-2 font-weight-bold"
              @click="dialoge = false"
              >Cancel</v-btn
            >
            <v-btn
              color="primary"
              class="body-2 font-weight-bold"
              outlined
              @click="deleteCategory()"
              >OK</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
      <!-- edit -->
      <v-form ref="form" v-model="valid">
        <v-dialog width="400px" v-model="editdialogue">
          <v-card width="400px">
            <v-toolbar dark color="#766BC0" dense flat class="body-2">
              <v-toolbar-title> Edit Item </v-toolbar-title>
            </v-toolbar>
            <!-- {{ curItem}} -->
            <v-layout wrap v-if="curItem">
              <v-flex xs12 px-4 pt-4>
                <v-text-field
                  v-model="curItem.floorName"
                  outlined
                  hide-details
                  dense
                  :rules="[(v) => !!v || 'This field is required']"
                ></v-text-field>
              </v-flex>
              <v-flex xs12 px-4 py-4>
                <v-text-field
                  v-model="curItem.floorNo"
                  outlined
                  hide-details
                  dense
                ></v-text-field>
              </v-flex>
             
            </v-layout>
            <v-card-actions class="pt-3">
              <v-spacer></v-spacer>
              <v-btn
                color="grey"
                text
                class="body-2 font-weight-bold"
                @click="editdialogue = false"
                >Cancel</v-btn
              >
              <v-btn
                color="primary"
                class="body-2 font-weight-bold"
                outlined
                @click="editCategory()"
                >OK</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-form>
      <!-- add -->
      <v-form ref="form">
        <v-dialog width="400px" v-model="adddialogue">
          <v-card width="400px">
            <v-toolbar dark color="#766BC0" dense flat class="body-2">
              <v-toolbar-title> Add Item </v-toolbar-title>
            </v-toolbar>

            <v-layout wrap>
               <v-flex xs12 px-4 pt-4>
                <v-select
                  :items="HostelList"
                  v-model="hostelName"
                  label="Hostel"
                  item-text="hostelName"
                  item-value="_id"
                  clearable
                  outlined
                  dense
                  :rules="[rules.required]"
                ></v-select>
              </v-flex>
              <v-flex xs12 px-4 pt-4>
                <v-text-field
                  autofocus
                  label="Floor Name"
                  v-model="myFloor"
                  outlined
                  dense
                  hide-details
                  :rules="[(v) => !!v || 'This field is required']"
                ></v-text-field>
              </v-flex>
              <v-flex xs12 px-4 py-4>
                <v-text-field
                  v-model="floorNo"
                  dense
                  type="number"
                  label="Floor Number"
                  outlined
                  hide-details
                  :rules="[(v) => !!v || 'This field is required']"
                ></v-text-field>
              </v-flex>
          
            </v-layout>
            <v-card-actions class="pt-3">
              <v-spacer></v-spacer>
              <v-btn
                color="grey"
                text
                class="body-2 font-weight-bold"
                @click="adddialogue = false"
                >Cancel</v-btn
              >
              <v-btn
                color="#766BC0"
                class="body-2 font-weight-bold"
                outlined
                @click="addCategory()"
                >OK</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-form>
        <v-layout wrap>
          <!-- <v-flex xs12 pt-4>
            <v-pagination small color="#766BC0" v-model="page" :length="Pagelength">
            </v-pagination>
          </v-flex> -->
        </v-layout>
      <v-snackbar
        :timeout="3000"
        :value="true"
        absolute
        centered
        color="red"
        v-model="showsnackbar"
      >
        <span style="font-size: 20px"> {{ msg }}</span>
      </v-snackbar>
    </v-flex>
  </v-layout>
</template>    
<script>
import axios from "axios";
export default {
  data() {
    return {
      valid: true,
      valid1: true,
      student: [],
      hostelName: null,
      hostel: null,
      HostelList: [],

       page: 1,
      Pagelength: 0,
      dialoge: false,
      editdialogue: false,  
      adddialogue: false,
      floorName: null,
      myFloor:null,
      curId: null,
      curItem: null,
      showsnackbar: false,
      msg: null,
      appLoading: false,
      ServerError: false,
      typeList: null,
      typee: "",
      quantitytypes: ["Count", "Weight", "Liters"],
      quantityType: null,
      productType: "",
      keyword: null,
      assetType: null,
      floorNo: null,
      weightType: ["Gram", "Kilo gram"],
      litersType: ["Milli Liter", "Liter"],
      rules: {
        required: (value) => !!value || "Required.",
        counter: (value) => value.length <= 20 || "Max 20 characters",
        phoneRules: [
          (v) => !!v || "phone is required",
          (v) =>
            (v && v.length <= 10) || "number must be less than 11 characters",
          (v) =>
            (v && v.length >= 10) ||
            "number must be greater than 10 characters",
          (v) => /^\d{10}$/.test(v) || "phone number must be a number",
        ],
        email: (value) => {
          const pattern =
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return pattern.test(value) || "Invalid e-mail.";
        },
      },
    };
  },
  mounted() {
    this.getData();
    this.gethostel();

  },
  watch: {
    keyword() {
      this.getData();
    },
     page() {
      // this.getData();
      this.getData();
    },
  },
  methods: {
    getData() {
      this.appLoading = true;
      axios({
        method: "get",
        url: "/floor/name/getlist",
        headers: {
          token: localStorage.getItem("token"),
        },
        params: {
          keyword: this.keyword,
           page: this.page,
          limit: 20,
          hostelName:this.hostel,
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status == true) {
            this.student = response.data.data;
           this.Pagelength = Math.ceil(response.data.totalLength / 20);
          } else {
            this.snackbar = true;
            this.msg = response.data.msg;
          }

          // this.msg = response.data.msg;

          // this.snackbar = true;
        })
        .catch((err) => {
          this.appLoading = false;
          console.log(err);
        });
    },
     gethostel() {
      axios({
        method: "get",
        url: "main/hostel/name/getlist",
        headers: {
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          if (response.data.status == true) {
            this.HostelList = response.data.data;
            this.hostelName = this.HostelList[0]._id;
          }
        })
        .catch((err) => {
          console.log(err());
        });
    },
    //delete button
    deleteCategory() {
      axios({
        url: "/floor/name/remove",
        method: "POST",
        headers: {
          token: localStorage.getItem("token"),
        },
        data: {
          id: this.curId,
        },
      })
        .then((response) => {
          this.delete = false;
          this.dialoge = false;
          this.appLoading = false;
          if (response.data.status) {
            this.msg = "Delete Sucessfully";
            // this.$router.go(-1)
            this.showsnackbar = true;
            this.getData();
          } else {
            this.msg = response.data.msg;
            this.showsnackbar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    //add button

    addCategory() {
  
      if (this.$refs.form.validate()) {
        axios({
          url: "/floorname/add",
          method: "POST",
          headers: {
            token: localStorage.getItem("token"),
          },
          data: {
            floorName: this.myFloor,
            floorNo: this.floorNo,
            hostelName: this.hostelName,
          },
        })
          .then((response) => {
            this.adddialogue = false;
            this.appLoading = false;
            if (response.data.status) {
              this.msg = response.data.msg;
              this.showsnackbar = true;
              this.floorName = null;
              this.floorNo = null;
              this.getData();
            } else {
              this.msg = response.data.msg;
              this.showsnackbar = true;
              this.floorName = null;
              this.floorNo = null;
              this.getData();
            }

            this.$refs.form1.resetValidation();
          })
          .catch((err) => {
            this.appLoading = false;
            this.ServerError = true;
            console.log(err);
          });
      }
    },
    //edit button
    editCategory() {
      axios({
        url: "/floor/name/edit",
        method: "POST",
        headers: {
          token: localStorage.getItem("token"),
        },
        data: {
          id: this.curItem._id,
          floorName: this.curItem.floorName,
          floorNo: this.curItem.floorNo,

        },
      })
        .then((response) => {
          this.editdialogue = false;
          this.appLoading = false;
          if (response.data.status) {
            this.msg = "Updated Sucessfully";
            // this.$router.go(-1)
            this.showsnackbar = true;
            this.getData();
          } else {
            this.msg = response.data.msg;
            this.showsnackbar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
  },
};
</script>